$primary: #1f53d7;

@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap");

#root {
  background-color: #fff;
  min-height: 100vh;
}
body {
  font-family: "Rubik", sans-serif !important;
  background-color: #fff;
}
button {
  text-transform: none !important;
}

a {
  text-decoration: none;
}
// fieldset {
//   top: 0 !important;
// }

.fs-sm {
  font-size: 14px !important;
}
.fs-xs {
  font-size: 12px !important;
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  @page {
    size: auto;
    margin: 20mm;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  div,
  p,
  table {
    page-break-inside: avoid;
  }
}

.sidebar-link:hover {
  color: #1f53d7 !important;
}

.active {
  color: #fff !important;
  background-color: #343a40 !important;
  border-radius: 4px !important;
}
.active:hover {
  color: #fff !important;
}

.ql-editor {
  min-height: 150px;
}

.text-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

// .ql-toolbar .ql-stroke {
//   fill: none;
//   stroke: #fff;
// }
// .ql-toolbar .ql-stroke:hover {
//   fill: none;
//   stroke: red;
// }

// .ql-toolbar .ql-fill {
//   fill: #fff;
//   stroke: none;
// }

// .ql-toolbar .ql-picker {
//   color: #fff;
// }
