@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}
img {
  width: auto;
  height: auto;
}
button {
  text-transform: capitalize !important;
  font-family: "Rubik", sans-serif !important;
  box-shadow: none !important;
}

td {
  white-space: nowrap !important;
}
th {
  white-space: nowrap !important;
}

.center {
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

.center2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-right {
  margin-right: 20px !important;
}

.space-right2 {
  margin-right: 10px !important;
}

.space-left {
  margin-left: 20px !important;
}

.space-left2 {
  margin-left: 10px !important;
}
.space-top {
  margin-top: 20px !important;
}

.space-top2 {
  margin-top: 10px !important;
}

.space-bottom {
  margin-bottom: 20px;
}

.space-bottom2 {
  margin-bottom: 10px;
}

.padding-x {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.padding-y {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padding {
  padding: 10px !important;
}
.padding2 {
  padding: 20px !important;
}

.margin {
  margin: 10px !important;
}
.margin-x {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.ruby {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.push-left-right {
  display: flex !important;
  justify-content: space-between !important;
}

.push-right {
  text-align: right;
  justify-content: flex-end !important;
}

.push-left {
  text-align: left;
  justify-content: flex-start !important;
}

.blue {
  color: #1f53d7 !important;
}

.click {
  cursor: pointer;
}
.text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #010a1b;
}

.bold {
  font-weight: 500 !important;
}

.sm {
  font-size: 13px !important;
}

.hr {
  /* background: #C4C4C4; */
  border: none;
  height: 0.1px;
}

.hr6 {
  background: #000 !important;
  height: 1.3px;
}

hr {
  background: #dfdfdf !important;
  border-width: 0.1px !important;
}

.text-truncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
